<template>
  <div>
    <a-modal
      :visible="visible"
      title="新增"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :maskClosable="false"
      @cancel="handleCancel"
      @ok="handleConfirm"
    >
      <a-form :form="dataForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="用户名">
          <a-input
            v-decorator="[
              'username',
              {
                rules: [
                  { required: true, message: '请输入用户名' },
                  { max: 32, message: '超出最大长度(32)' },
                ],
              },
            ]"
            :allowClear="true"
          />
        </a-form-item>
        <a-form-item label="单位名称">
          <UnitNameSelect
            v-decorator="[
              'unit_name',
              {
                rules: [
                  { required: true, message: '请输入单位名称' },
                  { max: 64, message: '超出最大长度(64)' },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="姓名">
          <a-input
            v-decorator="[
              'name',
              {
                rules: [
                  { required: true, message: '请输入姓名' },
                  { max: 64, message: '超出最大长度(64)' },
                ],
              },
            ]"
            :allowClear="true"
          />
        </a-form-item>
        <a-form-item label="电话">
          <a-input v-decorator="['phone', { rules: [{ max: 64, message: '超出最大长度(64)' }] }]" :allowClear="true" />
        </a-form-item>
        <a-form-item label="城市">
          <a-cascader v-decorator="['city_code', { initialValue: [] }]" placeholder="" :options="provinceAndCityData" />
        </a-form-item>
        <a-form-item label="类型">
          <a-select v-decorator="['type']" :allowClear="true" style="width: 100%">
            <a-select-option key="agent" value="agent">代理商</a-select-option>
            <a-select-option key="staff" value="staff">员工</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="等级">
          <a-select v-decorator="['level']" :allowClear="true" style="width: 100%">
            <a-select-option key="lv0" value="lv0">普通代理</a-select-option>
            <a-select-option key="lv1" value="lv1">银牌代理</a-select-option>
            <a-select-option key="lv2" value="lv2">金牌代理</a-select-option>
            <a-select-option key="lv3" value="lv3">钻石代理</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="备注">
          <a-input v-decorator="['remark', { rules: [{ max: 256, message: '超出最大长度(256)' }] }]" :allowClear="true" />
        </a-form-item>
        <a-form-item label="授权编号">
          <a-input
            v-decorator="['authorization_number', { rules: [{ max: 256, message: '超出最大长度(256)' }] }]"
            :allowClear="true"
          />
        </a-form-item>
        <a-form-item label="授权证书">
          <AuthorizationCertificateUpload v-decorator="['authorization_certificate_set', { initialValue: [] }]" />
        </a-form-item>
        <a-form-item label="激活状态">
          <a-select v-decorator="['is_active', { initialValue: 'T' }]" style="width: 100%">
            <a-select-option key="T" value="T">激活</a-select-option>
            <a-select-option key="F" value="F">冻结</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>

      <div style="color: rgb(255, 77, 79); text-align: center">默认初始密码为: 123456, 登录后请修改密码</div>
    </a-modal>
  </div>
</template>

<script>
import { provinceAndCityData } from "element-china-area-data";
import { userCreate } from "@/apis/system";

export default {
  components: {
    UnitNameSelect: () => import("@/components/UnitNameSelect"),
    AuthorizationCertificateUpload: () => import("@/components/AuthorizationCertificateUpload"),
  },
  props: ["visible"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      provinceAndCityData,
      confirmLoading: false,
      dataForm: null,
    };
  },
  methods: {
    handleConfirm() {
      this.dataForm.validateFields((error, values) => {
        if (error === null) {
          this.confirmLoading = true;
          userCreate(values)
            .then((data) => {
              this.$emit("create", data);
              this.$message.success("新增成功");
              this.handleCancel();
            })
            .catch((error) => {
              const errorFields = Object.entries(error).reduce((acc, [field, errors]) => {
                acc[field] = { value: values[field], errors: errors.map((value) => new Error(value)) };
                return acc;
              }, {});
              this.dataForm.setFields(errorFields);
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    },
    handleCancel() {
      this.$emit("cancel", false);
    },
  },
  mounted() {
    this.dataForm = this.$form.createForm(this);
  },
};
</script>

<style scoped></style>
